import { Button, message } from 'antd';
import { ethers, providers } from 'ethers';
import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import MyERC721 from './data/MyERC721.json';

const win = window as any;

function App() {
  const [signer, _signer] = useState<null | ethers.Signer>(null);

  const initProvider = useCallback(async () => {
    if (win.ethereum && win.ethereum.isMetaMask) {
      const pro = new providers.Web3Provider(win.ethereum);
      pro.on('accountsChanged', (addresss: string[]) => {
        window.location.reload();
      });
      pro.on('chainChanged', (chainId: string) => {
        window.location.reload();
      });
      await win.ethereum.request({ method: 'eth_requestAccounts' });
      message.info('connect success');
      _signer(pro.getSigner());
    }
  }, []);

  const deployContract = useCallback(async () => {
    if (!signer) return;
    const fac = new ethers.ContractFactory(MyERC721.abi, MyERC721.bytecode, signer);
    const contract = await fac.deploy('name', 'symbol');
    const res = await contract.deployed();
  }, [signer]);

  return (
    <div className="App">
      <Button onClick={initProvider} type={signer ? 'primary' : 'default'}>
        {signer ? 'Connected' : 'Connect Wallet'}
      </Button>
      <Button onClick={deployContract}>Deploy Contract</Button>
    </div>
  );
}

export default App;
